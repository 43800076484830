import React, { useEffect, useRef } from 'react'

type Options = IntersectionObserverInit & {
	onEnter?: () => void
	onLeave?: () => void
}

export default function useObserver(
	ref: React.RefObject<HTMLElement>,
	options?: Options
) {
	const isObserving = useRef<boolean>(false)
	useEffect(() => {
		if (!ref.current) {
			return
		}

		const onIntersect: IntersectionObserverCallback = (entries) => {
			let isIntersecting = false
			for (const entry of entries) {
				if (entry.isIntersecting) {
					isIntersecting = true
				}
			}
			if (isIntersecting) {
				if (typeof options?.onEnter === 'function' && !isObserving.current) {
					options.onEnter()
				}

				isObserving.current = true
			} else {
				if (typeof options?.onLeave === 'function' && isObserving.current) {
					options.onLeave()
				}
				isObserving.current = false
			}
		}

		const observer = new IntersectionObserver(onIntersect, {
			root: options?.root,
			rootMargin: options?.rootMargin,
			threshold: options?.threshold,
		})
		observer.observe(ref.current)

		return () => {
			observer.disconnect()
		}
	}, [])

	return isObserving
}
