import React, { useEffect } from 'react'
import styled from 'styled-components';
import Img from 'gatsby-image'
import Responsive from '../base/Responsive';
import zIndexes from '../../lib/styles/zIndexes';
import { down, up } from 'styled-breakpoints';
import { calculateGridWidth } from '../../lib/utils/calculateGridWidth';
import Typography from '../common/Typography';
import { useInView } from 'react-intersection-observer';
import { ColorType } from '../../context/InvertColorsContext';
import { useTranslation } from 'react-i18next';
import { fullWidth } from '../../lib/styles/utils';
import { graphql, useStaticQuery } from 'gatsby';

interface LabelingNinthSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingNinthSection = ({ invertColors }: LabelingNinthSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})

    useEffect(() => {
        invertColors(ColorType.WHITE)    
    }, [inView])

    const data = useStaticQuery(graphql`
        query {
            c1: file(relativePath: { eq: "images/labeling/c1.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            c2: file(relativePath: { eq: "images/labeling/c2.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            c3: file(relativePath: { eq: "images/labeling/c3.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            c4: file(relativePath: { eq: "images/labeling/c4.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            c5: file(relativePath: { eq: "images/labeling/c5.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            c6: file(relativePath: { eq: "images/labeling/c6.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            c7: file(relativePath: { eq: "images/labeling/c7.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            c8: file(relativePath: { eq: "images/labeling/c8.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            pt1: file(relativePath: { eq: "images/labeling/pt1.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            pt2: file(relativePath: { eq: "images/labeling/pt2.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            pt3: file(relativePath: { eq: "images/labeling/pt3a.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            pt4: file(relativePath: { eq: "images/labeling/pt4.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            pt5: file(relativePath: { eq: "images/labeling/pt5.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            pt6: file(relativePath: { eq: "images/labeling/pt99.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            pt7: file(relativePath: { eq: "images/labeling/pt7.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            pt8: file(relativePath: { eq: "images/labeling/pt8.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }            
        }
    `)


  return (
    <Background className='container' ref={ref}>
        <Block className='container'>
            <Typography.Heading 
                size='xlarge'
                fontWeight='bold'
                className='mainText' 
                color='dark100'
            >
                {t('LabelingNinthSection.heading1')}
            </Typography.Heading>
            <ImgBox>
                {/* <div className='ImgBoxRow'> */}
                    <ImgWrap>
                        <Img fluid={data.c1.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.c2.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.c3.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.c4.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                {/* </div>
                <div className='ImgBoxRow'> */}
                    <ImgWrap>
                        <Img fluid={data.c5.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.c6.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.c7.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.c8.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                {/* </div> */}
            </ImgBox>
            <Typography.Heading 
                size='xlarge'
                fontWeight='bold'
                className='mainText' 
                color='dark100'
                >
                {t('LabelingNinthSection.heading2')}
            </Typography.Heading>
            <ImgBox>
                {/* <div className='ImgBoxRow'> */}
                    <ImgWrap2>
                        <Img fluid={data.pt1.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap2>
                    <ImgWrap2>
                        <Img fluid={data.pt2.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap2>
                    <ImgWrap2>
                        <Img fluid={data.pt3.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap2>
                    <ImgWrap2>
                        <Img fluid={data.pt4.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap2>
                {/* </div>
                <div className='ImgBoxRow'> */}
                    <ImgWrap>
                        <Img fluid={data.pt5.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.pt6.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.pt7.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                    <ImgWrap>
                        <Img fluid={data.pt8.childImageSharp.fluid} alt='top' className='img' />
                    </ImgWrap>
                {/* </div> */}
            </ImgBox>
        </Block>
    </Background>
  )
}

export default LabelingNinthSection

const Background = styled.div`
    background: white;

    ${fullWidth}

`
const Block = styled(Responsive)`
	display: flex;
    flex-direction: column;
	margin: 0 auto;
	position: relative;
    align-items: center;
	z-index: ${zIndexes.medicalSecondSection};
    padding-top: 120px;
    padding-bottom: 30px;
    
	${up('lg')} {
        width: ${calculateGridWidth(10)};
	}
    
	${down('md')} {
        width: 100%;
	}
    
    > .mainText {
        margin-bottom: 30px;
    }
    `

const ImgBox = styled.div`
    width: 100%;
    padding-bottom: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* justify-content: space-between; */
    flex-flow: row wrap;
    /* > .ImgBoxRow {
        align-items: center;
        padding-bottom: 40px;
        height: 150px;
    } */
`

const ImgWrap = styled.div`
    ${up('sm')} {
		width: 25%;
	}

    ${down('xs')} {
		width: 33%;
	}

    height: 70px;
    display: inline-block;
    margin-bottom: 30px;

    > .img {
        height: 70px;
        > picture {
            margin: 0 auto;
        }
        > picture > img {
            width: 150px !important;
            height: 70px !important;
            object-fit: contain !important;
            left: 50% !important;
            margin-left: -75px;
        }
    }

    ${down('sm')} {
        height: 50px;
        > .img {
            height: 50px;
            > picture > img {
                width: 140px !important;
                height: 50px !important;
                object-fit: contain !important;
                left: 50% !important;
                margin-left: -70px;
            }
        }
    }
    ${down('xxs')} {
        height: 40px;
        > .img {
            height: 40px;
            > picture > img {
                width: 80px !important;
                height: 40px !important;
                object-fit: contain !important;
                left: 50% !important;
                margin-left: -40px;
            }
        }
    }
`
const ImgWrap2 = styled.div`
    height: 50px;
    width: 25%;
    > .img {
        height: 60px;
        > picture > img {
            width: 220px !important;
            height: 50px !important;
            object-fit: contain !important;
        }
    }
    
    ${down('sm')} {
        height: 50px;
        > .img {
            height: 50px;
            > picture > img {
                width: 160px !important;
                height: 50px !important;
                object-fit: contain !important;
            }
        }
    }
    
    ${down('xs')} {
        width: 33%;
        height: 50px;
        margin: 0 auto;
        margin-bottom: 30px;
        > .img {
            height: 50px;
            > picture > img {
                width:150px !important;
                height: 50px !important;
                object-fit: contain !important;
                left: 50% !important;
                margin-left: -75px;
            }
        }
    }

    ${down('xxs')} {
        height: 40px;
        > .img {
            height: 40px;
            > picture > img {
                width: 100px !important;
                height: 40px !important;
                object-fit: contain !important;
                left: 50% !important;
                margin-left: -50px;
            }
        }
    }

    margin-bottom: 30px;

`