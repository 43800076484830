import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '../common/Typography'
import { useTranslation } from 'react-i18next'
import { ColorType } from '../../context/InvertColorsContext'
import { useInView } from 'react-intersection-observer'
import { down } from 'styled-breakpoints'


interface LabelingEighthSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingTenthSection = ({ invertColors }: LabelingEighthSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})
    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])

    return(
    <Box>
        <Typography.Display
        size='small'
        color='dark00'
        className='title'
        fontWeight='bold'
        >
            {t("LabelingTenthSection.heading") }
        </Typography.Display>
    </Box>
  )
}

LabelingTenthSection.propTypes = {}

export default LabelingTenthSection

const Box = styled.div`
    padding-top: 500px;
    padding-bottom: 500px;

    text-align: center;

    ${down('xs')} {
        padding-top: 200px;
        padding-bottom: 200px;

        > .title {
            font-size: 28px;
        }
    }
`