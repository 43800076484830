import React, { useEffect } from 'react'
import styled from 'styled-components';
import Img, { FluidObject } from 'gatsby-image'
import Responsive from '../base/Responsive';
import zIndexes from '../../lib/styles/zIndexes';
import { between, down, only, up } from 'styled-breakpoints';
import { GUTTER, calculateGridWidth } from '../../lib/utils/calculateGridWidth';
import Typography from '../common/Typography';
import { useInView } from 'react-intersection-observer';
import { ColorType } from '../../context/InvertColorsContext';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql, useStaticQuery } from 'gatsby';
import breakpoints from '../../lib/styles/breakpoints';
import { BodyType, DisplayType, HeadingType } from '../../lib/styles/typography';

interface LabelingFourthSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingFifthSection = ({ invertColors }: LabelingFourthSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})
    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])
    
    const breakpoints = useBreakpoint()
    let descSize: HeadingType = 'large'
    let titleSize: DisplayType = 'medium'

    if (breakpoints.xs === true) {
      descSize = 'xsmall'
      titleSize = 'small'
    }

    const data = useStaticQuery(graphql`
    query {
        image1: file(relativePath: { eq: "images/labeling/p1.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        },
        image2: file(relativePath: { eq: "images/labeling/p2.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        },
        image3: file(relativePath: { eq: "images/labeling/p3.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        ,
        image4: file(relativePath: { eq: "images/labeling/p4.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        ,
        image5: file(relativePath: { eq: "images/labeling/p5.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        ,
        image6: file(relativePath: { eq: "images/labeling/p6.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`)

  return (
    <div ref={ref}>
        <Block>
            <HeadingTextBox>
              <Typography.Display
              size={titleSize}
              fontWeight='bold'
              color='dark00'
              className='title'
              >
              {t('LabelingFifthSection.heading')}
              </Typography.Display>
              <Typography.Heading
              size={descSize}
              fontWeight='regular'
              color='dark00'
              className='desc'
              >
              {t('LabelingFifthSection.description')}
              </Typography.Heading>
            </HeadingTextBox>
            <Item title={t('LabelingFifthSection.t1')} desc={t('LabelingFifthSection.d1')} img={data.image1.childImageSharp.fluid} imgLeft={true}/>
            <Item title={t('LabelingFifthSection.t2')} desc={t('LabelingFifthSection.d2')} img={data.image2.childImageSharp.fluid} imgLeft={false}/>
            <Item title={t('LabelingFifthSection.t3')} desc={t('LabelingFifthSection.d3')} img={data.image3.childImageSharp.fluid} imgLeft={true}/>
            <Item title={t('LabelingFifthSection.t4')} desc={t('LabelingFifthSection.d4')} img={data.image4.childImageSharp.fluid} imgLeft={false}/>
            <Item title={t('LabelingFifthSection.t5')} desc={t('LabelingFifthSection.d5')} img={data.image5.childImageSharp.fluid} imgLeft={true}/>
            <Item title={t('LabelingFifthSection.t6')} desc={t('LabelingFifthSection.d6')} img={data.image6.childImageSharp.fluid} imgLeft={false}/>
        </Block>
    </div>
  )
}

interface ItemProps {
  title: string
  desc: string
  img: FluidObject
  imgLeft: boolean
}


const Item = ({ title, desc, img, imgLeft }: ItemProps) => {
  const breakpoints = useBreakpoint()
  let titleSize: HeadingType = 'medium'

  if (breakpoints.xs === true) {
    titleSize = 'small'
  }
  return (
    <ItemBox imgLeft={imgLeft}>
      <ItemImageWrapper>
        <Img fluid={img} alt='top' className='img' />
      </ItemImageWrapper>
      <ItemContent imgLeft={imgLeft}>
        <Typography.Heading
          size={titleSize}
          fontWeight='bold'
          color='dark00'
          className='title'
          >
            {title}
          </Typography.Heading>  
          <Typography.Body
          size='large'
          fontWeight='regular'
          color='dark00'
          className='desc'
          >
            {desc}
          </Typography.Body>
      </ItemContent>
    </ItemBox>
  )
}
export default LabelingFifthSection

interface ItemBoxProps {
    imgLeft: boolean
}

const ItemBox = styled.div<ItemBoxProps>`
  display: flex;
  align-items: center;
  margin-bottom: 100px;

  ${up('md')} {
    flex-direction: ${props => props.imgLeft === true ? 'row' : 'row-reverse'};
  }

  ${down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${only('sm')} {
    padding-left: ${calculateGridWidth(1, true)};
    margin-left: ${GUTTER}px;
  }
  
`

const ItemImageWrapper = styled.div`
display: flex;
justify-content: center;

  ${up('lg')} {
    width: ${calculateGridWidth(5)};
  }

  ${only('md')} {
    width: ${calculateGridWidth(3, true)};
  }

  ${down('sm')} {
    width: ${calculateGridWidth(4, true)};
    margin-bottom: 75px;
  }
  
  ${down('xs')} {
    max-width: 280px;
  }

  > .img {
    width: 100%;
  }
  
`
const ItemContent = styled.div<ItemBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  

  ${up('lg')} {
      width: ${calculateGridWidth(6)};
      ${props => props.imgLeft === true ?
    `padding-left: ${calculateGridWidth(1)};
        margin-left: ${GUTTER *2}px;
    `
    :
    ` padding-right: ${calculateGridWidth(1)};
        margin-right: ${GUTTER *2}px;
    `
  }
    }

${only('md')} {
    width: ${calculateGridWidth(5, true)};
        ${props => props.imgLeft === true ?
    `
        margin-left: ${GUTTER}px;
    `
    :
    ` 
        margin-right: ${GUTTER *2}px;
    `
  }
}

  > .desc {
    
    ${up('lg')} {
      width: ${calculateGridWidth(5)};
    }

    ${up('md')} {
        padding-top: 60px;
    }
    ${down('md')} {
      width: ${calculateGridWidth(4, true)};
    }

    ${down('sm')} {
        padding-top: 50px;
    }

    ${only('xxs')} {
        padding-top: 33px;
        width: 100%;
    }

  }
`


const Block = styled(Responsive)`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	position: relative;
  padding-bottom: 200px;

	${up('xl')} {
		width: ${calculateGridWidth(12)};
	}
`

const HeadingTextBox = styled.div`
  padding-bottom: 180px;
  text-align: center;

${down('sm')} {
    padding-bottom: 90px;
}
  > .title {
    display: inline-block;
    ${only('sm')} {
        width: 320px;
    }

    ${down('xs')} {
        width: 250px;
    }
  }

  > .desc {
    padding-top: 40px;
  }
`
