import React, { useEffect } from 'react'
import styled from 'styled-components';
import Img from 'gatsby-image'
import Responsive from '../base/Responsive';
import zIndexes from '../../lib/styles/zIndexes';
import { down, up } from 'styled-breakpoints';
import { calculateGridWidth } from '../../lib/utils/calculateGridWidth';
import Typography from '../common/Typography';
import { useInView } from 'react-intersection-observer';
import { ColorType } from '../../context/InvertColorsContext';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../assets/images/labeling/databg.png'
import { fullWidth } from '../../lib/styles/utils';
import { graphql, useStaticQuery } from 'gatsby';

interface LabelingSecondSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingSecondSection = ({ invertColors }: LabelingSecondSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})

    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])

    const data = useStaticQuery(graphql`
        query {
            image1: file(relativePath: { eq: "images/labeling/databrain.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)


  return (
    <Background className='container' ref={ref}>
        <Block className='container'>
            <ImageWrapper>
                <Img fluid={data.image1.childImageSharp.fluid} alt='top' className='img' />
            </ImageWrapper>
            <MainTextBox>
            <Typography.Heading 
                size='xxlarge'
                fontWeight='bold'
                className='mainText' 
                color='dark00'
            >
                {t('LabelingSecondSection.heading')}
            </Typography.Heading>
            <Typography.Heading
                size='small'
                fontWeight='regular'
                className='description'
                color='dark00'
            >
                {t('LabelingSecondSection.description')}
            </Typography.Heading>
            </MainTextBox>
        </Block>
    </Background>
  )
}

export default LabelingSecondSection

const Background = styled.div`
    background-image: linear-gradient(black, transparent 25%, transparent), linear-gradient(transparent, black 85%), ${`url('${BackgroundImage}')`};

    ${fullWidth}

`
const Block = styled(Responsive)`
	display: flex;
	justify-content: space-between;
    flex-direction: row;
	margin: 0 auto;
	position: relative;
    align-items: center;
	z-index: ${zIndexes.medicalSecondSection};
    padding-top: 400px;
    padding-bottom: 500px;

	${up('xl')} {
		width: ${calculateGridWidth(12)};
	}

	${down('md')} {
		flex-direction: column;
        padding-top: 220px;
	}
`
const ImageWrapper = styled.div`
    width: 586px;

    ${down('md')} {
        width: 420px;
    }

    ${down('xxs')} {
        width: 200px;
    }
`
const MainTextBox = styled.div`
	display: flex;
	flex-direction: column;
	white-space: nowrap;
    
    ${down('md')} {
        text-align: center;
        padding-top: 60px
    }

	> .mainText {
        padding-bottom: 32px;
		${down('xxs')} {
			font-size: 24px;
			line-height: 32px;
            padding-bottom: 20px;
		}

        @media (max-width: 332px) {
            font-size: 24px;
            line-height: 38px;
        }
	}

    > .description {
        ${down('xxs')} {
            font-size: 14px;
            line-height: 20px;
        }
    }
`