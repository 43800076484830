import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { down, up } from 'styled-breakpoints'
import zIndexes from '../../lib/styles/zIndexes'
import useObserver from '../../lib/hooks/useObserver'

interface ShapeAnimationProps {
	isColored?: boolean
	isBrowswerSafariNotMobile?: boolean
}

const ShapeAnimation = ({
	isColored,
	isBrowswerSafariNotMobile,
}: ShapeAnimationProps) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const polygonShadowRef = useRef<HTMLDivElement>(null)
	const polygonRef = useRef<HTMLDivElement>(null)
	const timeout = useRef<NodeJS.Timeout | null>(null)
	let index = 0

	const morph = () => {
		if (polygonShadowRef.current && polygonRef.current && !isColored) {
			polygonRef.current.className = polygons[index].className
			polygonRef.current.style.cssText = `
				-webkit-clip-path: polygon(${polygons[index].polygon});
				clip-path: polygon(${polygons[index].polygon});
			`
			index++
			index = index === polygons.length ? 0 : index
		}
		if (polygonShadowRef.current && polygonRef.current && isColored) {
			polygonRef.current.className = polygons[index].className
			polygonRef.current.style.cssText = `
				-webkit-clip-path: polygon(${polygons[index].polygon}); 
				clip-path: polygon(${polygons[index].polygon}); 
				background: ${polygons[index].background};
			`
			polygonShadowRef.current.style.cssText = `
				filter : drop-shadow(0px 0px 32px ${polygons[index].background});
			`
			index++
			index = index === polygons.length ? 0 : index
		}
	}

	useObserver(containerRef, {
		threshold: 0,
		onEnter: () => {
			morph()
			timeout.current = global.setInterval(morph, 1000)
		},
		onLeave: () => {
			timeout.current && clearTimeout(timeout.current)
		},
	})

	const polygons = isBrowswerSafariNotMobile
		? [
				{
					className: 'polygon hexagon',
					polygon: '50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%',
					background: 'rgba(255, 224, 58, 0.8)',
				},
		  ]
		: [
				{
					className: 'polygon hexagon',
					polygon: '50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%',
					background: 'rgba(255, 224, 58, 0.8)',
				},
				{
					className: 'polygon triangle',
					polygon: '50% 0%, 100% 100%, 100% 100%, 50% 100%, 0% 100%, 0% 100%',
					background: 'rgba(255, 58, 181, 0.8)',
				},
				{
					className: 'polygon pentagon',
					polygon: '50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%, 0% 38%',
					background: 'rgba(58, 255, 172, 0.8)',
				},
		  ]

	return (
		<Container ref={containerRef}>
			<Polygon
				className="polygonShadow"
				ref={polygonShadowRef}
				isColored={isColored}
			>
				<div
					className={isColored ? 'polygon isColored' : 'polygon'}
					ref={polygonRef}
				></div>
			</Polygon>
		</Container>
	)
}

const Container = styled.div``

const Polygon = styled.div<{ isColored?: boolean }>`
	--xl-width: 300px;
	--lg-width: 280px;
	--md-width: 220px;
	--sm-width: 170px;
	--xs-width: 130px;
	--colored-xl-width: 380px;
	--colored-lg-width: 380px;
	--colored-md-width: 360px;
	--colored-sm-width: 320px;
	--colored-xs-width: 280px;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: ${zIndexes.shapeAnimation};
	transition: all 0.35s ease;

	${(props) =>
		!props.isColored &&
		css`
			right: -0.5rem;
			bottom: -1rem;
			filter: drop-shadow(-16px -16px 16px rgba(255, 255, 255, 0.9));
			${up('lg')} {
				right: 12rem;
			}
		`}

	.polygon {
		transition: all 0.35s ease;
		background: linear-gradient(
			180deg,
			#ffffff 25%,
			rgba(255, 255, 255, 0) 90%
		);
	}
	.hexagon {
		${(props) =>
			!props.isColored &&
			css`
				width: var(--xl-width);
				height: calc(var(--xl-width) * 1.1);

				${down('lg')} {
					width: var(--lg-width);
					height: calc(var(--lg-width) * 1.1);
				}
				${down('md')} {
					width: var(--md-width);
					height: calc(var(--md-width) * 1.1);
				}
				${down('sm')} {
					width: var(--sm-width);
					height: calc(var(--sm-width) * 1.1);
				}
				${down('xs')} {
					width: var(--xs-width);
					height: calc(var(--xs-width) * 1.1);
				}
			`}

		${(props) =>
			props.isColored &&
			css`
				width: var(--colored-xl-width);
				height: calc(var(--colored-xl-width) * 1.12);
				${down('lg')} {
					width: var(--colored-lg-width);
					height: calc(var(--colored-lg-width) * 1.12);
				}
				${down('md')} {
					width: var(--colored-md-width);
					height: calc(var(--colored-md-width) * 1.12);
				}
				${down('sm')} {
					width: var(--colored-sm-width);
					height: calc(var(--colored-sm-width) * 1.12);
				}
				${down('xs')} {
					width: var(--colored-xs-width);
					height: calc(var(--colored-xs-width) * 1.12);
				}
			`};
	}

	.triangle {
		${(props) =>
			!props.isColored &&
			css`
				width: calc(var(--xl-width) * 1.14);
				height: var(--xl-width);
				${down('lg')} {
					width: calc(var(--lg-width) * 1.14);
					height: var(--lg-width);
				}
				${down('md')} {
					width: calc(var(--md-width) * 1.14);
					height: var(--md-width);
				}
				${down('sm')} {
					width: calc(var(--sm-width) * 1.14);
					height: var(--sm-width);
				}
				${down('xs')} {
					width: calc(var(--xs-width) * 1.14);
					height: var(--xs-width);
				}
			`}

		${(props) =>
			props.isColored &&
			css`
				width: calc(var(--colored-xl-width) * 1.14);
				height: var(--colored-xl-width);
				${down('lg')} {
					width: calc(var(--colored-lg-width) * 1.14);
					height: var(--colored-lg-width);
				}
				${down('md')} {
					width: calc(var(--colored-md-width) * 1.14);
					height: var(--colored-md-width);
				}
				${down('sm')} {
					width: calc(var(--colored-sm-width) * 1.14);
					height: var(--colored-sm-width);
				}
				${down('xs')} {
					width: calc(var(--colored-xs-width) * 1.14);
					height: var(--colored-xs-width);
				}
			`}
	}

	.pentagon {
		${(props) =>
			!props.isColored &&
			css`
				width: var(--xl-width);
				height: calc(var(--xl-width) * 0.98);
				${down('lg')} {
					width: var(--lg-width);
					height: calc(var(--lg-width) * 0.98);
				}
				${down('md')} {
					width: var(--md-width);
					height: calc(var(--md-width) * 0.98);
				}
				${down('sm')} {
					width: var(--sm-width);
					height: calc(var(--sm-width) * 0.98);
				}
				${down('xs')} {
					width: var(--xs-width);
					height: calc(var(--xs-width) * 0.98);
				}
			`}

		${(props) =>
			props.isColored &&
			css`
				width: var(--colored-xl-width);
				height: calc(var(--colored-xl-width) * 0.98);
				${down('lg')} {
					width: var(--colored-lg-width);
					height: calc(var(--colored-lg-width) * 0.98);
				}
				${down('md')} {
					width: var(--colored-md-width);
					height: calc(var(--colored-md-width) * 0.98);
				}
				${down('sm')} {
					width: var(--colored-sm-width);
					height: calc(var(--colored-sm-width) * 0.98);
				}
				${down('xs')} {
					width: var(--colored-xs-width);
					height: calc(var(--colored-xs-width) * 0.98);
				}
			`}
	}
	@keyframes morph {
		0% {
			transform: rotate(0deg) scale(1);
		}

		50% {
			transform: rotate(40deg) scale(1.1);
		}

		100% {
			transform: rotate(40deg) scale(1);
		}
	}
`

export default ShapeAnimation
