import React, { useEffect } from 'react'
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { ColorType } from '../../context/InvertColorsContext';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

interface LabelingIntroSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingThirdSection = ({ invertColors }: LabelingIntroSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})

    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])

    const breakpoints = useBreakpoint()

let Content = XLargeContent
if (breakpoints.sm === true) {
  Content = XSContent
} else if (breakpoints.md === true) {
  Content = SMContent
} else if (breakpoints.lg === true) {
  Content = MDContent
}

  return (
    <Block ref={ref}>
        <div>
          <Content />
        </div>
    </Block>
  )
}

export default LabelingThirdSection

const Block = styled.div`
    margin-top: -350px;
    padding-bottom: 250px;
    display: flex;
	  justify-content: center;

  @media (max-width: 350px) {
      transform:scale(0.85)
  }
`



const XLargeContent = () =>  (
    <div style={{width: 1046, height: 1959, position: 'relative'}}>
    <div style={{width: 176, height: 176, left: 32, top: 373, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
      <div style={{width: 176, left: 0, top: 50, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 'bold', lineHeight: '36px', wordWrap: 'break-word'}}>AI<br/>Consultant</div>
    </div>
    <div style={{width: 176, height: 176, left: 32, top: 1098, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
      <div style={{width: 176, left: 0, top: 50, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 'bold', lineHeight: '36px', wordWrap: 'break-word'}}>AI<br/>Consultant</div>
    </div>
    <div style={{width: 176, height: 176, left: 240, top: 0, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Client<br/>[Source Data]</div>
    </div>
    <div style={{width: 176, height: 176, left: 240, top: 596, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Modeling Strategy</div>
    </div>
    <div style={{width: 176, height: 176, left: 240, top: 1321, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Reviewed<br/>Data</div>
    </div>
    <div style={{width: 176, height: 176, left: 552, top: 1552, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Automatic<br/>Annotation</div>
    </div>
    <div style={{width: 176, height: 176, left: 240, top: 1783, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#340E65'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 'bold', lineHeight: '36px', wordWrap: 'break-word'}}>Final<br/>Product</div>
    </div>
    <div style={{width: 176, height: 176, left: 240, top: 827, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Data Structure & Guidelines</div>
    </div>
    <div style={{width: 176, height: 176, left: 240, top: 1552, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Prototype<br/>Modeling</div>
    </div>
    <div style={{width: 176, height: 176, left: 867, top: 1098, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Annotated<br/>Data</div>
    </div>
    <div style={{width: 176, height: 176, left: 448, top: 374, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}}></div>
      <div style={{width: 87, left: 45, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 'bold', lineHeight: '36px', wordWrap: 'break-word'}}>Domain<br/>Expert</div>
    </div>
    <div style={{width: 263, left: 410, top: 857, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '24px', wordWrap: 'break-word'}}>Pre-trained Model을<br/>사용한 초도 레이블링</div>
    <div style={{width: 208, left: 0, top: 778, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>모델 학습 전략과 데이터 구조를 구상하고 가이드라인을 점검합니다. </div>
    <div style={{width: 208, left: 744, top: 778, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>가이드라인에 따라<br/>가공 작업이 진행됩니다.</div>
    <div style={{width: 159, left: 257, top: 1145, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>의료인 등 도메인 전문가와 함께 데이터 검수를 진행합니다.</div>
    <div style={{width: 291, left: 625, top: 1409, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>프로토타입 학습을 통해 AI 적합성을 검증하며, 오토라벨링을 통해 작업속도를 높입니다.</div>
    <div style={{width: 339, left: 457, top: 1843, position: 'absolute'}}><span style={{color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>전문가 검수와 AI 모델링 테스트가 완료된 </span><span style={{color: 'white', fontSize: 20, fontWeight: 'bold', lineHeight: '28px', wordWrap: 'break-word'}}>최적의 학습 데이터셋</span><span style={{color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>이 완성됩니다.</span></div>
    <div style={{left: 470, top: 70, position: 'absolute', color: 'white', fontSize: 24, fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>클라이언트 보유 원천 데이터 </div>
    <div style={{width: 176, height: 176, left: 448, top: 1098, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}}></div>
      <div style={{width: 87, left: 45, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 'bold', lineHeight: '36px', wordWrap: 'break-word'}}>Domain<br/>Expert</div>
    </div>
    <div style={{width: 182, height: 176, left: 864, top: 374, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 'bold', lineHeight: '36px', wordWrap: 'break-word'}}>Professional<br/>Annotators</div>
    </div>
    <div style={{width: 182, height: 176, left: 864, top: 1552, position: 'absolute'}}>
      <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
      <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 'bold', lineHeight: '36px', wordWrap: 'break-word'}}>Professional<br/>Annotators</div>
    </div>
    <div style={{width: 286, height: 0, left: 328, top: 176, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 240, height: 0, left: 208, top: 462, position: 'absolute', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 137, height: 0, left: 120, top: 550, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 368, height: 0, left: 120, top: 1272, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 137, height: 0, left: 536, top: 550, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 137, height: 0, left: 536, top: 1272, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 240, height: 0, left: 416, top: 915, position: 'absolute', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 446, height: 0, left: 656, top: 915, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 120, height: 0, left: 120, top: 687, position: 'absolute', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 120, height: 0, left: 120, top: 1640, position: 'absolute', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 120, height: 0, left: 536, top: 687, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 120, height: 0, left: 536, top: 1409, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 208, height: 0, left: 656, top: 469, position: 'absolute', border: '1px #143DA7 solid'}}></div>
    <div style={{width: 55, height: 0, left: 328, top: 772, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
    <div style={{width: 55, height: 0, left: 328, top: 1497, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
    <div style={{width: 55, height: 0, left: 328, top: 1728, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #340E65 solid'}}></div>
    <div style={{width: 136, height: 0, left: 416, top: 1640, position: 'absolute', border: '1px #1E5754 solid'}}></div>
    <div style={{width: 136, height: 0, left: 728, top: 1640, position: 'absolute', border: '1px #1E5754 solid'}}></div>
    <div style={{width: 278, height: 0, left: 955, top: 1552, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
    <div style={{width: 243, height: 0, left: 867, top: 1186, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
    <div style={{width: 549, height: 0, left: 955, top: 549, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
    <div style={{left: 694, top: 1314, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Accelerated<br/>Annotation!</div>
    <div style={{width: 102, height: 99, left: 605, top: 643, position: 'absolute', background: '#9E5F00'}} />
    <div style={{width: 87, left: 613, top: 658, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Cloud<br/>ETL</div>
  </div>
)

const MDContent = () => (

    <div style={{width: 933, height: 1959, position: 'relative'}}>
  <div style={{width: 176, height: 176, left: 0, top: 373, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 50, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>AI<br/>Consultant</div>
  </div>
  <div style={{width: 176, height: 176, left: 0, top: 1098, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 50, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>AI<br/>Consultant</div>
  </div>
  <div style={{width: 176, height: 176, left: 208, top: 0, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Client<br/>[Source Data]</div>
  </div>
  <div style={{width: 176, height: 176, left: 208, top: 596, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Modeling Strategy</div>
  </div>
  <div style={{width: 176, height: 176, left: 208, top: 1321, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Reviewed<br/>Data</div>
  </div>
  <div style={{width: 176, height: 176, left: 476, top: 1552, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Automatic<br/>Annotation</div>
  </div>
  <div style={{width: 176, height: 176, left: 208, top: 1783, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#340E65'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Final<br/>Product</div>
  </div>
  <div style={{width: 176, height: 176, left: 208, top: 827, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Data Structure & Guidelines</div>
  </div>
  <div style={{width: 176, height: 176, left: 208, top: 1552, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Prototype<br/>Modeling</div>
  </div>
  <div style={{width: 176, height: 176, left: 751, top: 1098, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Annotated<br/>Data</div>
  </div>
  <div style={{width: 176, height: 176, left: 416, top: 374, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}}></div>
    <div style={{width: 87, left: 45, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Domain<br/>Expert</div>
  </div>
  <div style={{width: 263, left: 378, top: 857, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '24px', wordWrap: 'break-word'}}>Pre-trained Model을<br/>사용한 초도 레이블링</div>
  <div style={{width: 208, left: 0, top: 773, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>모델 학습 전략과 데이터 구조를 구상하고 가이드라인을 점검합니다. </div>
  <div style={{width: 208, left: 644, top: 778, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>가이드라인에 따라<br/>가공 작업이 진행됩니다.</div>
  <div style={{width: 159, left: 225, top: 1145, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>의료인 등 도메인 전문가와 함께 데이터 검수를 진행합니다.</div>
  <div style={{width: 291, left: 510, top: 1409, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>프로토타입 학습을 통해 AI 적합성을 검증하며, 오토라벨링을 통해 작업속도를 높입니다.</div>
  <div style={{left: 400, top: 70, position: 'absolute', color: 'white', fontSize: 24, fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>클라이언트 보유 원천 데이터 </div>
  <div style={{width: 339, left: 425, top: 1843, position: 'absolute'}}><span style={{color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>전문가 검수와 AI 모델링 테스트가 완료된 </span><span style={{color: 'white', fontSize: 20, fontWeight: 700, lineHeight: '28px', wordWrap: 'break-word'}}>최적의 학습 데이터셋</span><span style={{color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>이 완성됩니다.</span></div>
  <div style={{width: 176, height: 176, left: 416, top: 1098, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}}></div>
    <div style={{width: 87, left: 45, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Domain<br/>Expert</div>
  </div>
  <div style={{width: 182, height: 176, left: 751, top: 374, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Professional<br/>Annotators</div>
  </div>
  <div style={{width: 182, height: 176, left: 744, top: 1552, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Professional<br/>Annotators</div>
  </div>
  <div style={{width: 286, height: 0, left: 296, top: 176, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 240, height: 0, left: 176, top: 462, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 137, height: 0, left: 88, top: 550, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 368, height: 0, left: 88, top: 1272, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 137, height: 0, left: 504, top: 550, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 137, height: 0, left: 504, top: 1272, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 240, height: 0, left: 384, top: 915, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 446, height: 0, left: 624, top: 915, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 120, height: 0, left: 88, top: 687, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 120, height: 0, left: 88, top: 1640, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 120, height: 0, left: 504, top: 687, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 120, height: 0, left: 504, top: 1409, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 208, height: 0, left: 624, top: 469, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 55, height: 0, left: 296, top: 772, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 55, height: 0, left: 296, top: 1497, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 55, height: 0, left: 296, top: 1728, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #340E65 solid'}}></div>
  <div style={{width: 92, height: 0, left: 384, top: 1640, position: 'absolute', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 98, height: 0, left: 646, top: 1640, position: 'absolute', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 278, height: 0, left: 835, top: 1552, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 243, height: 0, left: 835, top: 1186, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 549, height: 0, left: 839, top: 549, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{left: 579, top: 1314, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Accelerated<br/>Annotation!</div>
  <div style={{width: 102, height: 99, left: 573, top: 643, position: 'absolute', background: '#9E5F00'}} />
  <div style={{width: 87, left: 581, top: 658, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Cloud<br/>ETL</div>
</div>
)

const SMContent = () => (
    <div style={{width: 698, height: 2593, position: 'relative'}}>
  <div style={{width: 176, height: 176, left: 1, top: 373, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 50, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>AI<br/>Consultant</div>
  </div>
  <div style={{width: 176, height: 176, left: 257, top: 0, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Client<br/>[Source Data]</div>
  </div>
  <div style={{width: 176, height: 176, left: 257, top: 596, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Modeling Strategy</div>
  </div>
  <div style={{width: 176, height: 176, left: 513, top: 1788, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Reviewed<br/>Data</div>
  </div>
  <div style={{width: 176, height: 176, left: 0, top: 2044, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Automatic<br/>Annotation</div>
  </div>
  <div style={{width: 176, height: 176, left: 257, top: 2300, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#340E65'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Final<br/>Product</div>
  </div>
  <div style={{width: 176, height: 176, left: 257, top: 827, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Data Structure & Guidelines</div>
  </div>
  <div style={{width: 176, height: 176, left: 257, top: 1532, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Annotated<br/>Data</div>
  </div>
  <div style={{width: 176, height: 176, left: 513, top: 374, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}}></div>
    <div style={{width: 87, left: 45, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Domain<br/>Expert</div>
  </div>
  <div style={{width: 263, left: 345, top: 1050, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '24px', wordWrap: 'break-word'}}>Pre-trained Model을<br/>사용한 초도 레이블링</div>
  <div style={{width: 286, left: 375, top: 199, position: 'absolute', color: 'white', fontSize: 24, fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>클라이언트 보유 원천 데이터</div>
  <div style={{width: 208, left: 19, top: 730, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>모델 학습 전략과 데이터 구조를 구상하고 가이드라인을 점검합니다. </div>
  <div style={{width: 208, left: 213, top: 1302, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>가이드라인에 따라<br/>가공 작업이 진행됩니다.</div>
  <div style={{width: 159, left: 539, top: 1419, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>의료인 등 도메인 전문가와 함께 데이터 검수를 진행합니다.</div>
  <div style={{width: 339, left: 183, top: 2537, position: 'absolute', textAlign: 'center'}}><span style={{color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>전문가 검수와 AI 모델링 테스트가 완료된 </span><span style={{color: 'white', fontSize: 20, fontWeight: 700, lineHeight: '28px', wordWrap: 'break-word'}}>최적의 학습 데이터셋</span><span style={{color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>이 완성됩니다.</span></div>
  <div style={{width: 176, height: 176, left: 513, top: 1532, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}}></div>
    <div style={{width: 87, left: 45, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Domain<br/>Expert</div>
  </div>
  <div style={{width: 182, height: 176, left: 1, top: 1302, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Professional<br/>Annotators</div>
  </div>
  <div style={{width: 286, height: 0, left: 345, top: 176, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 336, height: 0, left: 177, top: 462, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 137, height: 0, left: 89, top: 550, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 203, height: 0, left: 91, top: 1196, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 169, height: 0, left: 177, top: 1391, position: 'absolute', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 141, height: 0, left: 345, top: 1144, position: 'absolute', transform: 'rotate(-89.59deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 137, height: 0, left: 601, top: 550, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 168, height: 0, left: 89, top: 687, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 169, height: 0, left: 602, top: 687, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 107, height: 0, left: 92, top: 1195, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 80, height: 0, left: 608, top: 1708, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 80, height: 0, left: 608, top: 1964, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 55, height: 0, left: 345, top: 772, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 80, height: 0, left: 346, top: 2220, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #340E65 solid'}}></div>
  <div style={{width: 143, height: 0, left: 345, top: 1389, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 80, height: 0, left: 433, top: 1620, position: 'absolute', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 80, height: 0, left: 513, top: 2130, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 80, height: 0, left: 257, top: 2130, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 566, height: 0, left: 89, top: 2044, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 102, height: 99, left: 294, top: 1144, position: 'absolute', background: '#9E5F00'}} />
  <div style={{width: 87, left: 302, top: 1159, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Cloud<br/>ETL</div>
  <div style={{width: 176, height: 176, left: 257, top: 2044, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: 0, top: 52, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>Prototype<br/>Modeling</div>
  </div>
  <div style={{width: 176, height: 176, left: 513, top: 2044, position: 'absolute'}}>
    <div style={{width: 176, height: 176, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: 0, top: 50, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>AI<br/>Consultant</div>
  </div>
  <div style={{width: 291, left: 158, top: 1871, position: 'absolute', color: 'white', fontSize: 20, fontWeight: 300, lineHeight: '28px', wordWrap: 'break-word'}}>프로토타입 학습을 통해 AI 적합성을 검증하며, 오토라벨링을 통해 작업속도를 높입니다.</div>
  <div style={{left: 227, top: 1776, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 24, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '36px', wordWrap: 'break-word'}}>Accelerated<br/>Annotation!</div>
</div>
)


const XSContent = () => (
<div style={{width: 337, height: 1734, position: 'relative'}}>
  <div style={{width: 108, height: 113, left: 13, top: 183, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '20px', wordWrap: 'break-word'}}>AI<br/>Consultant</div>
  </div>
  <div style={{width: 108, height: 113, left: 225, top: 1408, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '20px', wordWrap: 'break-word'}}>AI<br/>Consultant</div>
  </div>
  <div style={{width: 108, height: 113, left: 13, top: 820, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '20px', wordWrap: 'break-word'}}>Professional<br/>Annotators</div>
  </div>
  <div style={{width: 108, height: 113, left: 121, top: 366, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Modeling<br/>Strategy</div>
  </div>
  <div style={{width: 108, height: 113, left: 121, top: 521, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Data Structure<br/>& Guidelines</div>
  </div>
  <div style={{width: 108, height: 113, left: 121, top: 973, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Annotated<br/>Data</div>
  </div>
  <div style={{width: 108, height: 113, left: 225, top: 1265, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Reviewed<br/>Data</div>
  </div>
  <div style={{width: 108, height: 113, left: 13, top: 1408, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Prototype<br/>Modeling</div>
  </div>
  <div style={{width: 108, height: 113, left: 13, top: 1551, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#340E65'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '20px', wordWrap: 'break-word'}}>Final<br/>Product</div>
  </div>
  <div style={{width: 108, height: 113, left: 13, top: 1265, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#1E5754'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Automatic<br/>Annotation</div>
  </div>
  <div style={{width: 108, height: 113, left: 225, top: 183, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '20px', wordWrap: 'break-word'}}>Domain<br/>Expert</div>
  </div>
  <div style={{width: 108, height: 113, left: 225, top: 1124, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '20px', wordWrap: 'break-word'}}>Domain<br/>Expert</div>
  </div>
  <div style={{width: 42, height: 0, left: 173, top: 479, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 92, left: 245, top: 460, position: 'absolute', color: 'white', fontSize: 14, fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>모델 학습 전략과 데이터 구조를 구상하고 가이드라인을 점검합니다. </div>
  <div style={{width: 46, height: 0, left: 179, top: 680, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 108, height: 113, left: 119, top: 0, position: 'absolute'}}>
    <div style={{width: 108, height: 113, left: 0, top: 0, position: 'absolute', background: '#04257B'}} />
    <div style={{width: 176, left: -34, top: 37, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Client<br/>[Source Data]</div>
  </div>
  <div style={{width: 157, left: 0, top: 642, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Pre-trained Model을<br/>사용한 초도 레이블링</div>
  <div style={{left: 177, top: 126, position: 'absolute', color: 'white', fontSize: 14, fontWeight: 300, lineHeight: '36px', wordWrap: 'break-word'}}>클라이언트 보유 원천 데이터</div>
  <div style={{width: 208, left: 91, top: 760, position: 'absolute', color: 'white', fontSize: 14, fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>가이드라인에 따라<br/>가공 작업이 진행됩니다.</div>
  <div style={{width: 93, left: 240, top: 918, position: 'absolute', color: 'white', fontSize: 14, fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>의료인 등 도메인 전문가와 함께 데이터 검수를 진행합니다.</div>
  <div style={{width: 232, left: 13, top: 1694, position: 'absolute'}}><span style={{color: 'white', fontSize: 14, fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>전문가 검수와 AI 모델링 테스트가 완료된 </span><span style={{color: 'white', fontSize: 14, fontWeight: 700, lineHeight: '20px', wordWrap: 'break-word'}}>최적의 학습 데이터셋</span><span style={{color: 'white', fontSize: 14, fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>이 완성됩니다.</span></div>
  <div style={{width: 127, height: 0, left: 173, top: 111, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 126, height: 0, left: 67, top: 296, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 127, height: 0, left: 279, top: 296, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 109.50, height: 0, left: 120, top: 238, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 87, height: 0, left: 68, top: 707, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 53, height: 0, left: 121, top: 877, position: 'absolute', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 53, height: 0, left: 229, top: 1029, position: 'absolute', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 54, height: 0, left: 67, top: 423, position: 'absolute', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 49, height: 0, left: 279, top: 422, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 113, height: 0, left: 69, top: 707, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 28, height: 0, left: 282, top: 1237, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #143DA7 solid'}}></div>
  <div style={{width: 94, height: 0, left: 173, top: 877, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 95, height: 0, left: 281, top: 1029, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 28, height: 0, left: 282, top: 1378, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 28, height: 0, left: 67, top: 1521, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 30, height: 0, left: 67, top: 1408, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 332.01, height: 0, left: 69, top: 1265, position: 'absolute', transform: 'rotate(-90.35deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 104, height: 0, left: 225, top: 1465, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', border: '1px #1E5754 solid'}}></div>
  <div style={{width: 65, height: 65, left: 147, top: 675, position: 'absolute', background: '#9E5F00'}} />
  <div style={{width: 87, left: 135, top: 690, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 300, lineHeight: '20px', wordWrap: 'break-word'}}>Cloud<br/>ETL</div>
  <div style={{width: 127, left: 87, top: 1171, position: 'absolute', color: 'white', fontSize: 12, fontWeight: 300, lineHeight: '16px', wordWrap: 'break-word'}}>프로토타입 학습을 통해 AI 적합성을 검증하며, 오토라벨링을 통해 작업속도를 높입니다.</div>
  <div style={{left: 108, top: 1116, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inconsolata', fontWeight: 700, lineHeight: '20px', wordWrap: 'break-word'}}>Accelerated<br/>Annotation!</div>
</div>
)