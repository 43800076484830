import React, { useEffect } from 'react'
import styled from 'styled-components';
import Responsive from '../base/Responsive';
import zIndexes from '../../lib/styles/zIndexes';
import { down, only, up } from 'styled-breakpoints';
import { calculateGridWidth } from '../../lib/utils/calculateGridWidth';
import Typography from '../common/Typography';
import { useInView } from 'react-intersection-observer';
import { ColorType } from '../../context/InvertColorsContext';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

interface LabelingIntroSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingIntroSection = ({ invertColors }: LabelingIntroSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})
    const breakpoints = useBreakpoint()
    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])


  return (
    <div ref={ref}>
        <Block>
            <MainTextBox>
            <Typography.Display 
                size='large'
                fontWeight='bold'
                className='mainText' 
                color='dark00'
            >
                {breakpoints.sm ? t('LabelingIntroSection.headingXS') : t('LabelingIntroSection.heading')}
            </Typography.Display>
            <Typography.Heading
                size='medium'
                fontWeight='regular'
                className='description'
                color='dark00'
            >
                {t('LabelingIntroSection.description')}
            </Typography.Heading>
            </MainTextBox>
        </Block>
    </div>
  )
}

export default LabelingIntroSection

const Block = styled(Responsive)`
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	position: relative;
	z-index: ${zIndexes.medicalSecondSection};

	${up('xl')} {
		width: ${calculateGridWidth(12)};
	}

    ${only('lg')} {
        width: ${calculateGridWidth(10)};
    }
`

const MainTextBox = styled.div`
	display: flex;
	flex-direction: column;
	white-space: nowrap;
    padding-top: 448px;
    
    ${down('sm')} {
        padding-top: 380px;
    }

    ${down('xs')} {
        padding-top: 170px;
    }

	> .mainText {
        padding-bottom: 32px;
		${down('xs')} {
			font-size: 32px;
			line-height: 40px;
            padding-bottom: 20px;
		}

        @media (max-width: 332px) {
            font-size: 28px;
            line-height: 38px;
        }
	}

    > .description {
        ${down('xs')} {
            font-size: 18px;
            line-height: 28px;
        }
    }
`