import React, { useEffect } from 'react'
import styled from 'styled-components';
import Img, { FluidObject } from 'gatsby-image'
import Responsive from '../base/Responsive';
import { down, only, up } from 'styled-breakpoints';
import { GUTTER, calculateGridWidth } from '../../lib/utils/calculateGridWidth';
import Typography from '../common/Typography';
import { useInView } from 'react-intersection-observer';
import { ColorType } from '../../context/InvertColorsContext';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

interface LabelingSeventhSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingSeventhSection = ({ invertColors }: LabelingSeventhSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})
    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])

    const data = useStaticQuery(graphql`
    query {
        image1: file(relativePath: { eq: "images/labeling/hicon1.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        },
        image2: file(relativePath: { eq: "images/labeling/hicon2.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        },
        image3: file(relativePath: { eq: "images/labeling/hicon3.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`)

  return (
    <div ref={ref}>
        <Block>
            <HeadingTextBox>
              <Typography.Display
              size="small"
              fontWeight='bold'
              color='dark00'
              className='title'
              >
              {t('LabelingSeventhSection.heading')}
              </Typography.Display>
            </HeadingTextBox>
            <Item title={t('LabelingSeventhSection.t1')} desc={t('LabelingSeventhSection.d1')} img={data.image1.childImageSharp.fluid} />
            <Item title={t('LabelingSeventhSection.t2')} desc={t('LabelingSeventhSection.d2')} img={data.image2.childImageSharp.fluid}/>
            <Item title={t('LabelingSeventhSection.t3')} desc={t('LabelingSeventhSection.d3')} img={data.image3.childImageSharp.fluid}/>
        </Block>
    </div>
  )
}

const Block = styled(Responsive)`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	position: relative;
  padding-bottom: 200px;

	${up('lg')} {
		width: ${calculateGridWidth(8)};
	}

  ${down('md')} {
      width: ${calculateGridWidth(6, true)};
  }

  ${down('xs')} {
      width: ${calculateGridWidth(4, true)};
  }

  ${down('xxs')} {
    width: 100%
  }
`

const HeadingTextBox = styled.div`
  padding-bottom: 120px;
  text-align: center;

  ${down('xs')} {
    padding-bottom: 64px;
  }

  > .title {
    ${down('xxs')} {
      font-size: 28px;   
    }
  }
`


interface ItemProps {
  title: string
  desc: string
  img: FluidObject
}
const Item = ({ title, desc, img }: ItemProps) => {
  return (
    <ItemBox>
      <ItemImageWrapper>
        <Img fluid={img} alt='top' className='img' />
      </ItemImageWrapper>
      <ItemContent>
        <Typography.Heading
          size='medium'
          fontWeight='bold'
          color='dark00'
          className='title'
          >
            {title}
          </Typography.Heading>  
          <Typography.Body
          size='large'
          fontWeight='regular'
          color='dark00'
          className='desc'
          >
            {desc}
          </Typography.Body>
      </ItemContent>
    </ItemBox>
  )
}
export default LabelingSeventhSection

const ItemBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 60px;
  
`
const ItemImageWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
  margin-right: ${GUTTER}px;
  ${up('sm')} {
    width: ${calculateGridWidth(2)};
  }
  ${down('sm')} {
    width: ${calculateGridWidth(1, true)};
  }
  ${down('xs')} {
    width: 70px;
  }
  

  > .img {
    width: 110px;

    ${down('xxs')} {
      width: 60px;
    }
  }
  
`

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;


  > .title {
    ${down('xxs')} {
      font-size: 18px;
      line-height: 24px;
    }
  }
  > .desc {
    padding-top: 20px;
    ${up('lg')} {
      width: ${calculateGridWidth(5)};
    }
    ${only('md')} {
      width: ${calculateGridWidth(4, true)};
    }

    ${down('sm')} {
      width: auto;
      flex: 1;
    }

    ${down('xxs')} {
      padding-top: 14px;
    }
  }
`


