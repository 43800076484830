import React, { useEffect } from 'react'
import styled from 'styled-components';
import Responsive from '../base/Responsive';
import { down, only, up } from 'styled-breakpoints';
import { COLUMN_WIDTH_LARGE_SCREEN, GUTTER, calculateGridWidth } from '../../lib/utils/calculateGridWidth';
import Typography from '../common/Typography';
import { useInView } from 'react-intersection-observer';
import { ColorType } from '../../context/InvertColorsContext';
import { useTranslation } from 'react-i18next';

interface LabelingEighthSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingEighthSection = ({ invertColors }: LabelingEighthSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})
    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])


  return (
    <div ref={ref}>
        <Block>
            <HeadingTextBox>
              <Typography.Display
              size="small"
              fontWeight='bold'
              color='dark00'
              className='title'
              >
              {t('LabelingEighthSection.heading')}
              </Typography.Display>
              <Typography.Heading 
              size='xsmall'
              fontWeight='regular'
              color='dark00'
              className='desc'>
                {t('LabelingEighthSection.desc')}
              </Typography.Heading>
            </HeadingTextBox>
            <ItemsBox>
              <Item>
              <Typography.Heading
              size='medium'
              fontWeight='bold'
              color='dark00'
              className='title'
              >{t('LabelingEighthSection.t1')}</Typography.Heading>
              <Typography.Display
              size='small'
              fontWeight='bold'
              color='dark00'
              className='num'
              >30</Typography.Display>
              </Item>
              <Item>
              <Typography.Heading
              size='medium'
              fontWeight='bold'
              color='dark00'
              className='title'
              >{t('LabelingEighthSection.t2')}</Typography.Heading>
              <Typography.Display
              size='small'
              fontWeight='bold'
              color='dark00'
              className='num'
              >15</Typography.Display>
              </Item>
            </ItemsBox>
        </Block>
    </div>
  )
}

const Block = styled(Responsive)`
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	position: relative;

	${up('lg')} {
		width: ${calculateGridWidth(10)};
	}

  ${down('md')} {
      width: 100%;
  }

  ${down('sm')} {
    flex-direction: column;
  }
`

const HeadingTextBox = styled.div`
  ${(up('lg'))} {
    width: ${calculateGridWidth(6)};
    margin-right: ${COLUMN_WIDTH_LARGE_SCREEN + GUTTER*2}px;
  }
  
  ${only('md')} {
    width: ${calculateGridWidth(5, true)};
    margin-right: ${GUTTER}px;
  }

  > .title {
    display: block;
    margin-bottom: 32px;

    ${only('md')} {
       width: ${calculateGridWidth(5, true)};
       font-size: 40px;
    }
    ${down('xxs')} {
      font-size: 28px;   
    }
  }

  > .desc {
    ${only('sm')} {
      width: ${calculateGridWidth(4, true)}
    }
  }
`

export default LabelingEighthSection

const ItemsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  
  ${down('sm')} {
    padding-top: 60px;
    margin: 0;
    align-items: flex-start;
  }
  `

const Item = styled.div`
  text-align: center;
  margin: 0 auto;

  ${up('md')} {
  > .title {
    margin-bottom: 16px;
  }
  
  > .num {
    margin-bottom: 40px;
    display: block;
  }
}

  ${down('sm')} {
    display: flex; 
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    margin: 0;
    margin-bottom: 24px;

    > .title {
      width: ${calculateGridWidth(3, true)};
    }
  
  > .num {
    display: block;
  }
  }

  ${down('xxs')} {
    > .title {
      width: ${calculateGridWidth(2, true)};
    }

    > .num {
   font-size: 30px;
  }
  }
  
`
