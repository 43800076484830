import React from 'react'
import styled from 'styled-components'

import SEO from '../components/base/SEO'
import Layout from '../components/base/Layout'
import InvertColorsContext from '../context/InvertColorsContext'
import LabelingIntroSection from '../components/labeling/LabelingIntroSection'
import LabelingSecondSection from '../components/labeling/LabelingSecondSection'
import LabelingThirdSection from '../components/labeling/LabelingThirdSection'
import LabelingFourthSection from '../components/labeling/LabelingFourthSection'
import LabelingFifthSection from '../components/labeling/LabelingFifthSection'
import LabelingSixthSection from '../components/labeling/LabelingSixthSection'
import LabelingSeventhSection from '../components/labeling/LabelingSeventhSection'
import LabelingEighthSection from '../components/labeling/LabelingEighthSection'
import LabelingNinthSection from '../components/labeling/LabelingNinthSection'
import LabelingTenthSection from '../components/labeling/LabelingTenthSection'
import LabelingContactSection from '../components/labeling/LabelingContactSection'
import LabelingCTASection from '../components/labeling/LabelingCTASection'

const metaData = {
	title: '데이터라벨링 | Tesser',
	description: '의료/헬스케어 인공지능 개발을 위한 데이터 가공. 테서와 함께하세요.',
	keywords:
		'tesser, 테서, 태서, 데이터바우처, AI바우처, 병원데이터, 데이터가공, 데이터전처리, 임상데이터, 의료데이터, medical AI, 데이터 라벨링, 어노테이션, annotation, labeling, 헬스케어 데이터, MRI 가공, MRI 라벨링, CT 라벨링, X레이 라벨링, 의료인 라벨링, 의사 라벨링, 오토라벨링, 의료 AI 데이터, 의료 데이터 가공',
}

function Labeling() {
	return (
		<Layout color="black" blackFooter blackBackground>
			<SEO metaData={metaData} />
			<InvertColorsContext.Consumer>
				{({ invertColors }) => (
					<Block>
                        <LabelingIntroSection invertColors={invertColors}/>
                        <LabelingSecondSection invertColors={invertColors}/>
                        <LabelingThirdSection invertColors={invertColors}/>
                        <LabelingFourthSection invertColors={invertColors}/>
                        <LabelingFifthSection invertColors={invertColors}/>
                        <LabelingSixthSection invertColors={invertColors}/>
                        <LabelingSeventhSection invertColors={invertColors}/>
                        <LabelingEighthSection invertColors={invertColors}/>
                        <LabelingNinthSection invertColors={invertColors}/>
                        <LabelingTenthSection invertColors={invertColors}/>
                        <LabelingContactSection invertColors={invertColors}/>
						<LabelingCTASection invertColors={invertColors} />
					</Block>
				)}
			</InvertColorsContext.Consumer>
		</Layout>
	)
}

const Block = styled.div`
	min-height: 150vh;
	margin: 0 auto;

	> * {
		margin-bottom: 10rem;
	}
`

export default Labeling
