import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ColorType } from '../../context/InvertColorsContext'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { down, up } from 'styled-breakpoints'
import { calculateGridWidth } from '../../lib/utils/calculateGridWidth'
interface LabelingContactSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingContactSection = ({ invertColors }: LabelingContactSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})
    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])

    
    const [contactFormData, setContactFormData] = useState({
		companyName: '',
		name: '',
		phoneNumber: '',
		email: '',
		inquiryContent: '',
	})

	const changeContactFormData = ({ target }) => {
		const newInputValue = {}
		newInputValue[target.name] = target.value
		setContactFormData({
			...contactFormData,
			...newInputValue,
		})
	}

	const submitDataVoucher = (event) => {
		if (
			Object.values(contactFormData)
				.map((value) => value.split(' ').join(''))
				.includes('')
		) {
			event?.preventDefault()
			alert('입력란을 모두 입력해주세요.')
		} else {
			try {
				fetch('https://formspree.io/f/mgebagyv', {
					method: 'POST',
					body: JSON.stringify(contactFormData),
				})
					.then((response) => response.json())
					.then((result) => alert(result))
				alert('접수가 완료되었습니다.')
			} catch (error) {
				alert('죄송합니다. 문의 접수를 한번 더 부탁드립니다.')
			}
		}
	}

	return (
		<Block>
			<SectionDescriptionContainer>
				<DescriptionTitle>{t("LabelingContactSection.heading")}</DescriptionTitle>
				<DescriptionContent>
                {t("LabelingContactSection.description")} <br />
                {t("LabelingContactSection.description2")}
				</DescriptionContent>
			</SectionDescriptionContainer>
			<ContactFormContainer>
				<form onSubmit={submitDataVoucher}>
					<Input
						id="companyName"
						type="text"
						name="companyName"
						placeholder={t("LabelingContactSection.plCompany")}
                        onChange={changeContactFormData}
					/>
					<Input
						id="name"
						type="text"
						name="name"
						placeholder={t("LabelingContactSection.plName")}
                        onChange={changeContactFormData}
					/>
					<Input
						id="phoneNumber"
						type="number"
						name="phoneNumber"
						placeholder={t("LabelingContactSection.plPhone")}
                        onChange={changeContactFormData}
					/>
					<Input
						id="email"
						type="email"
						name="email"
						placeholder={t("LabelingContactSection.plEmail")}
                        onChange={changeContactFormData}
					/>
					<Textarea
						id="inquiryContent"
						name="inquiryContent"
						placeholder={t("LabelingContactSection.plContent")}
                        onChange={changeContactFormData}
					/>
					<Button>문의 접수</Button>
				</form>
			</ContactFormContainer>
		</Block>
	)
}

const Block = styled.section`
	display: flex;
	${up('lg')} {
		flex-direction: row;
        width: ${calculateGridWidth(14)};
		align-items: flex-start;
		padding: 128px 218px 240px 218px;
		gap: 35px;
	}
	${down('md')} {
		flex-direction: row;
		align-items: flex-start;
		gap: 32px;
	}

    ${down('sm')} {
        flex-direction: column;
    }
	${down('xs')} {
		gap: 32px;
	}
`


const SectionDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	${down('md')} {
		padding: 0px;
		gap: 40px;
		width: 383px;
	}

    ${down('xs')} {
        gap: 20px;
    }

`

const DescriptionTitle = styled.div`
	color: white;
	font-weight: 700;
    font-family: 'Pretendard', 'Inter';

    width: 274px;
    font-style: normal;
    font-size: 64px;
    line-height: 72px;
    display: flex;
    align-items: center;

	/* @media (min-width: 689px) and (max-width: 1279px) {
		font-style: normal;
		font-size: 40px;
		line-height: 52px;
	} */
	@media (max-width: 688px) {
        font-size: 36px;
		line-height: 48px;
	}
`

const DescriptionContent = styled.div`
	color: white;

    width: 383px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;

	/* @media (min-width: 689px) and (max-width: 1279px) {
		padding-top: 10px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
	} */
	@media (max-width: 688px) {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
	}
`

const ContactFormContainer = styled.div`
	display: flex;
	flex-direction: column;
    flex: 1;

    ${down('sm')} {
        width: 80%;
    }

    ${down('xs')} {
        width: 100%;
    }
`

const Input = styled.input`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px 24px;
	margin-bottom: 20px;
	gap: 10px;
	border: none;
	background: #fff;

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;

	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::placeholder {
		font-style: normal;
		display: flex;
		align-items: center;
		color: #a2a2a2;
	}

    width: 100%;
    ${down('md')} {
		height: 64px;
	}
	
	${down('xs')} {
		font-size: 16px;
        line-height: 24px;
		height: 50px;
	}
`
const Textarea = styled.textarea`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px 24px;
	margin-top: 16px;
	border: none;
	resize: none;
	gap: 10px;
	background: #fff;

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;

	::placeholder {
		font-style: normal;
		font-weight: 400;
		display: flex;
		align-items: center;
		color: #a2a2a2;
		flex: none;
		order: 0;
		flex-grow: 1;
	}

	
	width: 100%;
	height: 256px;
	
	/* @media (min-width: 689px) and (max-width: 1279px) {
		width: 567px;
		width: 80vw;
		height: 256px;
	} */
	${down('xs')} {
        font-size: 16px;
        line-height: 24px;
		height: 312px;
	}
`

const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 20px;
	margin-top: 43px;
	margin-left: 22px;
	gap: 8px;
	width: 213px;
	height: 70px;
	background: white;
	border-radius: 32px;
	color: black;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
`

export default LabelingContactSection
