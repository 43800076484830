import React, { useEffect } from 'react'
import styled from 'styled-components';
import Responsive from '../base/Responsive';
import { between, down, only, up } from 'styled-breakpoints';
import { COLUMN_WIDTH_LARGE_SCREEN, GUTTER, calculateGridWidth } from '../../lib/utils/calculateGridWidth';
import Typography from '../common/Typography';
import { useInView } from 'react-intersection-observer';
import { ColorType } from '../../context/InvertColorsContext';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql, useStaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image'
import { HeadingType } from '../../lib/styles/typography';

interface LabelingSixthSectionProps {
    invertColors: (colorType: ColorType) => void
}

const LabelingSixthSection = ({ invertColors }: LabelingSixthSectionProps) => {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
		threshold: 0.5,
	})
    useEffect(() => {
        invertColors(ColorType.BLACK)    
    }, [inView])

    const data = useStaticQuery(graphql`
    query {
        image1: file(relativePath: { eq: "images/labeling/s1.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        },
        image2: file(relativePath: { eq: "images/labeling/s2.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        },
    }
`)

const breakpoints = useBreakpoint()


  return (
    <div ref={ref}>
        <Block>
            <HeadingTextBox>
                <Typography.Display 
                    size='large'
                    fontWeight='bold'
                    className='title' 
                    color='dark00'
                >
                    {breakpoints.xs !== true ? t('LabelingSixthSection.heading') : t('LabelingSixthSection.headingXXS')}
                </Typography.Display>
            </HeadingTextBox>
            <ItemsBox>
                <Item img={data.image1.childImageSharp.fluid} title={t('LabelingSixthSection.t1')} desc={t('LabelingSixthSection.d1')} tags={['CT', 'MRI', 'Text', 'Image', 'Keypoint', 'Numerical']}/>
                <Item img={data.image2.childImageSharp.fluid} title={t('LabelingSixthSection.t2')} desc={t('LabelingSixthSection.d2')} tags={['CT/MRI Segmentation', 'Language Model', 'OCR', 'Pathology Segmentation', 'Image Classification']}/>
            </ItemsBox>
        </Block>
    </div>
  )
}

export default LabelingSixthSection


  
const Block = styled(Responsive)`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 250px;

    ${up('xl')} {
        width: ${calculateGridWidth(10)};
    }
`

const HeadingTextBox = styled.div`
    padding-bottom: 70px;

    ${down('xs')} {
        padding-bottom: 50px;
    }

    > .title {
        ${down('sm')} {
            font-size: 40px;
            line-height: 48px;
        }

        ${down('xxs')} {
            font-size: 28px;
            line-height: 36px;
        }
    }
`

const ItemsBox = styled.div`
    display: flex;
    flex-direction: row;

    ${down('sm')} {
        flex-direction: column;
    }
`



interface ItemProps {
    title: string
    desc: string
    img: FluidObject
    tags: Array<string>
  }
  
  
  const Item = ({ title, desc, img, tags }: ItemProps) => {
    const breakpoints = useBreakpoint()
    let titleSize: HeadingType = 'medium'
  
    if (breakpoints.xs === true) {
      titleSize = 'small'
    }
    return (
      <ItemBox>
        <ItemImageWrapper>
          <Img fluid={img} alt='top' className='img' />
        </ItemImageWrapper>
        <ItemContent>
          <Typography.Heading
            size={titleSize}
            fontWeight='bold'
            color='dark00'
            className='title'
            >
              {title}
            </Typography.Heading>  
            <Typography.Body
            size='large'
            fontWeight='regular'
            color='dark00'
            className='desc'
            >
              {desc}
            </Typography.Body>
            <ItemTagBox>
                {tags.map(t => (
                    <ItemTag>
                        {t}
                    </ItemTag>
                ))}
                </ItemTagBox>
        </ItemContent>

      </ItemBox>
    )
  }
  
  
  const ItemBox = styled.div`
    display: flex;
    flex-direction: column;
  
    ${up('lg')} {
        width: ${calculateGridWidth(5)};
        margin-right: ${COLUMN_WIDTH_LARGE_SCREEN + GUTTER*2}px;
    }
    
    ${down('md')} {
        width: ${calculateGridWidth(4, true)};
        margin-right: ${GUTTER}px;
    }

    ${only('xxs')} {
        width: 100%
    }
  `
  
  const ItemImageWrapper = styled.div`
  display: flex;
  width: 100%;
  
    > .img {
      width: 100%;

      ${only('xxs')} {
        max-width: 375px;
      }
    }
    
  `
  const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    
    > .title {
        margin-top: 3rem;
        margin-bottom: 2rem;
    }

    > .desc {
        margin-bottom: 3rem;
    }
  `
  
  const ItemTagBox = styled.div`
    width: 100%;

    ${(down('sm'))} {
        margin-bottom: 120px;
    }
  `
  const ItemTag = styled.div`
    display: inline-block;
    background-color: white;
    font-weight: 700;
    padding: 10px 28px;
    margin-bottom: 18px;
    margin-right: 18px;
    border-radius: 32px;
    font-size: 20px;
    line-height: 28px;

    ${(down('sm'))} {
        padding: 6px 20px;
        margin-bottom: 14px;
        margin-right: 14px;
        border-radius: 28px;
        font-size: 16px;
        line-height: 24px;
    }

  `