import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { down } from 'styled-breakpoints'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'
import ButtonWithIcon from '../common/ButtonWithIcon'
import Responsive from '../base/Responsive'
import ShapeAnimation from '../main/ShapeAnimation'
import { fullWidth } from '../../lib/styles/utils'
import zIndexes from '../../lib/styles/zIndexes'
import { ColorType } from '../../context/InvertColorsContext'
import { Link } from 'gatsby'

interface ILabelingCTASectionProps {
	invertColors: (colorType: ColorType) => void
	isBrowswerSafariNotMobile?: boolean
}

function LabelingCTASection({
	invertColors,
	isBrowswerSafariNotMobile,
}: ILabelingCTASectionProps) {
	const breakpoints = useBreakpoint()
	const [ref, inView] = useInView({
		threshold: 0,
	})

	useEffect(() => {
		if (inView === true) {
			invertColors(ColorType.BLACK)
		}
		if (inView === false) {
			invertColors(ColorType.WHITE)
		}
	}, [inView])

	const { t } = useTranslation()

	return (
		<Block ref={ref}>
			<Responsive>
				<Typography.Display
					size="large"
					fontWeight="bold"
					className="title"
					color="dark00"
				>
					{breakpoints.sm ? (
						<>{t('MedicalCTASection.heading1')}</>
					) : (
						<>{t('MedicalCTASection.heading2')} </>
					)}
				</Typography.Display>
				<ShapeBox>
					<ShapeAnimation
						isColored={true}
						isBrowswerSafariNotMobile={isBrowswerSafariNotMobile}
					/>
				</ShapeBox>
				<Link to="/contact">
					<ButtonWithIcon
						className="cta"
						fontSize="20px"
						fontWeight="bold"
						color="white"
					>
						{t('MedicalCTASection.button')}
					</ButtonWithIcon>
				</Link>
			</Responsive>
		</Block>
	)
}

const Block = styled.section`
	${fullWidth};
	background-color: black;
	padding-top: 8rem;
	padding-bottom: 8rem;
	padding-left: 2rem;
	padding-right: 2rem;
	z-index: ${zIndexes.medicalCTA};
	.title {
		padding-bottom: 2rem;
		word-break: keep-all;
		max-width: 1216px;
	}
`

const ShapeBox = styled.div`
	position: absolute;
	right: 0;
	bottom: -6rem;
	z-index: ${zIndexes.shapeBox};
	${down('sm')} {
		bottom: -6rem;
	}
	${down('xs')} {
		left: 0;
		right: 20px;
	}
	.triangleShadow {
		z-index: ${zIndexes.triangleShadow};
	}
`

export default LabelingCTASection
